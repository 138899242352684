.whatsapp_block {

    &.fade-in {
        transition: opacity 1s ease;
    }

    &.fade-out {
        opacity: 0;
        transition: opacity 1s ease;
    }
    
    a {
        &:hover, &:focus, &:active, &.active {

            color: white;

        }
    }

    /* for desktop */
    .whatsapp_float {
        position: fixed;
        background-color: #25d366;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
        width: 70px;
        height: 70px;
        bottom: 30px;
        right: 30px;
        font-size: 50px;
        border-radius: 70px;
    }

    .whatsapp-icon {
        margin-top: 10px;
    }


    /* for mobile */
    @media screen and (max-width: 767px) {
        
        .whatsapp-icon {
            margin-top: 10px;
        }

        .whatsapp_float {
            width: 65px;
            height: 65px;
            bottom: 15px;
            right: 10px;
            font-size: 46px;
            border-radius: 65px;
        }
    }
}