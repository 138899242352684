html{
  min-height: 100%;
  position: relative;
}
body{
  // margin-bottom: 60px;
}

p {
  font-size: 1.25rem;
}

h1 {
  font-size: 3rem;
  font-weight: 700;

  &.big-title {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 0;
    // text-transform: uppercase;
    // text-shadow: 0 0 9px rgba(0,0,0,.4);
  }
}


h2 {
  font-size: 2.75rem;
  &.handwriting {
    font-size: 3.25rem;
  }
}


h3 {
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-size: 1.6rem;
  // line-height: 2.25rem;
}

.btn {

  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: .45rem 1.4rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0;
  
  &:hover {
    // background-color: darken($danger, .5);
  }
}

.benefits, .product {

  background-color: $gray;

  ul {
    margin-top: 30px;
    list-style: none;
    /* padding: 0; */
    -webkit-columns: 2;
    -moz-columns: 2;
    column-count: 2;
    grid-gap: 20px;
    gap: 20px;

    li  {
      display: grid;
      grid-template-columns: 20px auto;
      margin-bottom: 8px;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;

      span {
        margin-top: 8px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #27a327;
      }
    }
  }
}


@media (max-width: 767px) {

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
  
    &.big-title {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 1.90rem;
  }
  
  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.25rem;
  }

  .benefits, .product {

    ul {
      // margin-top: 30px;
      // list-style: none;
      padding: 0; 
      -webkit-columns: 1;
      -moz-columns: 1;
      column-count: 1;
      grid-gap: 40px;
      gap: 40px;
  
      li  {
        // display: grid;
        // grid-template-columns: 20px auto;
        margin-bottom: 12px;
        // page-break-inside: avoid;
        // -webkit-column-break-inside: avoid;
        // break-inside: avoid;
  
        span {
          margin-top: 8px;
          // width: 10px;
          // height: 10px;
          // border-radius: 50%;
          // background-color: #27a327;
        }
      }
    }
  }


}
