@import "./custom";
// @import "~bootstrap";
// @import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap.scss';

@import "./components/base";
@import "./components/hero";
@import "./components/slide";
@import "./components/navbar";
@import "./components/benefits";
@import "./components/availables";
@import "./components/product";
@import "./components/location";
@import "./components/cta";
@import "./components/tanks";
@import "./components/footer";
@import "./components/whatsapp";
