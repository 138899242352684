.tanks{
  text-align: center;
  // background-color: blue;
  margin-bottom: 5rem;

  h5{
    color: $primary;
  }

  h2{
    margin-bottom: 20px;
  }

  a{
    color: $secondary;
    text-decoration: none;
    .rrss_icon {
      // color: $cta;
    }
  }

  h5{
    margin-bottom: 10px;
  }

  p{
    font-size: 1.2em;
  }

  .btn{
    margin: 1rem 0;
    color: white;
  }

  

  .social{
    margin-bottom: 30px;
    a{
      padding: 0 8px;

    }
  }

  #btn_whatsapp{
    img {
      height: 1.6rem;
      padding-bottom: .25rem;
    }
  }


}

@media (max-width: 767px) {
  .tanks{
    margin-top: 3rem;
    h3{
      // color: $primary;
      margin-bottom: 20px;
    }

  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .tanks{

    margin-top: 6rem;

  }

}

@media (min-width: 992px) and (max-width: 1199px) {

  .tanks{
    margin-top: 7rem;
  }
  
}

@media (min-width: 1200px) {

  .tanks{
    margin-top: 7rem;
  }

}
