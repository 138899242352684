.navbar{
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  // margin-bottom: 20px;
  width: 100%;
  background-color:#111;;
  // position: absolute;
  // top: 0;
  // z-index: 99;
  
  .navbar-brand{
    margin-right: auto;
    margin-left: auto;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    img{
      height: 80px;
    }
  }

  &.isTanks{
    background-color: $primary;
    text-align: center;
    .navbar-brand{
     
    }
  }


}

@media (max-width: 767px) {
  .navbar{
    padding-top: 20px;
    padding-bottom: 16px;
    text-align: center;
  
    .navbar-brand{
      margin-right: auto;
      margin-left: auto;
      img{
        height: 50px;
      }
    }
  }
}