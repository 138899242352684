.cta {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white;
  // background-color: #333;
  background-color: lighten($color: #000000, $amount: 85);
  // color: white;
  text-align: center;

  .btn {
    // background-color: $danger;
    // &:hover {
    //   background-color: darken($danger, .5);
    // }
  }

  h3 {
    font-size: 1.6em;
    // margin-bottom: 2rem;
  }


  @media (max-width: 767px) {

      h3 {
          font-size: 1.4em;
      }

  }
}

.cta-2 {

  margin-bottom: 3.5rem;

} 