.slide {

  background-color: $secondary;
  // height:550px;
  overflow: hidden;

  .carousel{

    background-color: $primary;

    .carousel-item {

      height:550px;
      background-image: url('../../statics/hero-1920-x-700.jpg');
      background-size: cover;
      background-position: top right;

      .carousel-caption {

        height: 60%;
        bottom: 20%;
        width: 80%;
        left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        .caption-inner {

          h2 {
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 12px;
            text-shadow: 0 0 9px rgba(0,0,0,.4);
          }
          
        }
      }
  
    }

    // img {
    //   // height: 100%;
    //   // width: auto;
    //   object-fit: cover;
    // }
  }

  // .btn {
  //   background-color: $cta;
  //   &:hover {
  //     background-color: darken($cta, .5);
  //   }
  // }
  

  // .darken {
  //   height: 725px;
  // }

  // .content {
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;

  //   .container {

  //     .bajada {

  //       h1, h2, h3, h4, h5, p {
  //         color: white;
  //       }
  
  //       #logo_empresa {
  //         width:275px;
  //       }
  
  //     }
            
  //     .form {

  //       .btn {
  //         margin-top: 1rem;
  //         // text-transform: uppercase;
  //         color: white;
  //         &:hover {
  //           // background-color: $cta;
  //         }
  //       }
  //     }

  //   }
  // }
}

@media (min-width: 1200px) {

  .slide {
    // background-position: center bottom;
    // background-image: url('../../statics/hero-1920-x-700.jpg');

    // .bajada {
    //   h1 {
    //     margin-top: 60px;
    //   }
    // }

  }

}

@media (min-width: 768px) and (max-width: 1199px) {

  .slide {
    // background-position: center bottom;
    // background-image: url('../../statics/hero-1920-x-700.jpg');
    // .darken {
    //   // background-color: rgba(#000, 0.5);
    // }
    // .bajada {
    //   h1 {
    //     margin-top: 30px;
    //   }
    // }
  }

}

@media (max-width: 767px) {
  .slide {
    // background-position: center right;
    // background-image: url('../../statics/hero-1200-767.jpg');
    // .darken {
    //   height:1250px;
    //   background-color: rgba($secondary, 0.9);
    // }

    .content {
      // height: 100%;
      .container {
        text-align: center;

        // .bajada {
        //   h1 {
        //     margin-top: 10px;
        //   } 

        //   #logo_empresa {
        //     width: 250px;
        //   }
        // }

        // .form {
        //   margin-top: 1.5rem;
        //   margin-bottom: 1.5rem;
        // }

      }
    }
  }
}
